import Vue from 'vue'
import App from './App.vue'
import store from './store'

Vue.config.productionTip = false

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from "axios";

import Vuelidate from 'vuelidate'
import { faAddressBook, faArrowDown, faArrowUp, faBars, faCalendar, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faInfo, faInfoCircle, faLock, faLockOpen, faLongArrowAltDown, faLongArrowAltUp, faMicrophone, faMicrophoneSlash, faPauseCircle, faPhone, faPhoneVolume, faPlayCircle, faPowerOff, faSearch, faSort, faSortDown, faSortUp, faStopCircle, faSyncAlt, faThLarge, faThList, faTimesCircle, faUserPlus, faUsers, faUsersSlash, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import i18n from "@/utils/i18n";

library.add(
  faThList,
  faThLarge,
  faSearch,
  faChevronLeft,
  faChevronRight,
  faPhoneVolume,
  faSort,
  faSortUp,
  faSortDown,
  faInfo,
  faChevronDown,
  faChevronUp,
  faUserPlus,
  faAddressBook,
  faPhone,
  faVolumeUp,
  faPlayCircle,
  faPauseCircle,
  faStopCircle,
  faBars,
  faPowerOff,
  faMicrophone,
  faMicrophoneSlash,
  faLock,
  faLockOpen,
  faUsers,
  faUsersSlash,
  faSyncAlt,
  faTimesCircle,
  faCalendar,
  faInfoCircle,
  faArrowUp,
  faArrowDown,
);

Vue.component('fa-icon', FontAwesomeIcon);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuelidate)

let backendUrl = process.env.VUE_APP_BACKEND_URL
if (backendUrl === undefined) {
  console.log("No environment variable 'VUE_APP_BACKEND_URL' found. Use 'localhost:8080");
  backendUrl = "localhost:8080";
}
console.log("Backend-URL", backendUrl);
axios.defaults.baseURL = backendUrl;

new Vue({
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
